export const vehicleSides = [
  {
    alias: "front",
    view: "front view",
    url: "frontCapture.png",
  },
  {
    alias: "frontLeft",
    view: "front left",
    url: "frontLeftCapture.png",
  },
  {
    alias: "frontRight",
    view: "front right",
    url: "frontRightCapture.png",
  },
  {
    alias: "left",
    view: "driver side",
    url: "driverSideCaptureAlt.png",
  },
  {
    alias: "right",
    view: "passenger side",
    url: "passengerSideCaptureAlt.png",
  },
  {
    alias: "back",
    view: "full back view",
    url: "fullBackCapture.png",
  },
  {
    alias: "backLeft",
    view: "back left",
    url: "backLeftCapture.png",
  },
  {
    alias: "backRight",
    view: "back right",
    url: "backRightCapture.png",
  },
  {
    alias: "roof",
    view: "roof",
    url: "roof.png",
  },
  {
    alias: "odometer",
    view: "odometer",
    url: "Odometer.png",
  },
  {
    alias: "vin",
    view: "VIN",
    url: "vin.png",
  },
];

export const bikeSides = [
  {
    alias: "front",
    view: "front view",
    url: "BikeFront.png",
  },
  {
    alias: "left",
    view: "left side",
    url: "BikeLeftSide.png",
  },
  {
    alias: "right",
    view: "right side",
    url: "BikeRightSide.png",
  },
  {
    alias: "back",
    view: "full back view",
    url: "BikeBack.png",
  },
  {
    alias: "roof",
    view: "top view",
    url: "BikeTopView.png",
  },
  {
    alias: "odometer",
    view: "odometer",
    url: "BikeOdometer.png",
  },
  {
    alias: "vin",
    view: "VIN",
    url: "BikeVin.png",
  },
];
