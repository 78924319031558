import { baseURL, dbCollection, pages, balloonURL } from "../data/configs";
import axios from "../lib/axios";

export default async function setToken({
  setIsInvalidToken,
  setError,
  submitted,
  setIsClaims,
  setPage,
}) {
  console.log({ submitted }, "setToken");
  const hasToken = window.location.href.split("?token=")[1]?.length || null;
  localStorage.clear();
  if (hasToken && !localStorage.getItem("inspected")) {
    try {
      const request = axios();
      const token = new URLSearchParams(window.location.href.split("?")[1]).get(
        "token"
      );
      const isBalloon =
        new URLSearchParams(window.location.href.split("?")[1]).get(
          "platform"
        ) === "balloon";
      const resp = await request.get(
        `${isBalloon ? balloonURL : baseURL}/${dbCollection}/${token}`
      );
      const { userId, vehicleId, linkType } = isBalloon
        ? resp.data.data
        : resp.data;

      const user = localStorage.getItem("user");
      const vehicle = localStorage.getItem("vehicle");
      const isClaims = linkType === "Claim";

      if (setPage) {
        setPage((!isClaims && pages.vehicleCapture) || pages.vehicleInfo);
      }

      if (+user !== userId || +vehicle !== vehicleId) {
        // setWarning("Please clear your captures");
        setIsInvalidToken(true);
        localStorage.setItem("authentic", 0);
      }

      localStorage.setItem("user", userId);
      localStorage.setItem("vehicle", vehicleId);
      localStorage.setItem("authentic", 1);
    } catch (error) {
      console.error({ error });
      if (error.response.data.message) {
        localStorage.setItem("inspected", 0);
      }
      setError(error.response.data.message);
      setIsInvalidToken(true);
    }
  }
}
