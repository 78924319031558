import React from "react";
import styled from "styled-components";
import colors from "../../assets/colors";
import {
  screenSize,
  mobileScreen,
  laptopScreen,
  desktopScreen,
  tabletScreen,
} from "../../assets/screens";

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ text, subText, url }) {
  return (
    <div class="main-container">
      <div>
        <div>
          <img style={{ height: "150px" }} src={url} />
        </div>
        {/* <div class="check-background">
        <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="check-shadow"></div> */}
      </div>
      <h3 style={{ margin: "10px 0", color: colors.black }}>{text}</h3>
      <p
        style={{
          padding: "10px 45px",
          fontWeight: "600",
          color: colors.grey,
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        {subText}
      </p>
      <Link href="https://www.etapinsure.com">Learn more</Link>
    </div>
  );
}

const Link = styled("a")`
  text-decoration: none;
  background-color: #02d7f2;
  color: ${colors.black};
  padding: 15px 25px;
  font-weight: bold;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 15px;

  // ${(props) =>
    props?.disabled &&
    `
  //   & * {
  //     pointer-events: none;
  //     filter: grayscale(1);
  //   }
  // `}
`;
