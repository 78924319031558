import React from "react";
import { useState, useEffect } from "react";
// import { faHome } from "fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "fortawesome/react-fontawesome";
import styled from "styled-components";
import colors from "../../assets/colors";
import { laptopScreen, mobileScreen, screenSize } from "../../assets/screens";
import { captureIcon } from "../../data/configs";
import { FontAwesome } from "../Bootstrap";
import Slider from "./Slider";

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ operator }) {
  // const [selectionMode, setSelectionMode] = useState(captureMode.camera);
  const {
    disabled,
    uploadCompleted,
    goToNext,
    goToPrevious,
    slides,
    currentIndex,
    goToSlide,
    uploadProgress,
    captureMode,
  } = operator;
  const RightArrow = ArrowStyles("right");
  const LeftArrow = ArrowStyles("left");

  return (
    <ArrowGroup>
      <LeftArrow color={colors.blue3} dir="left" onClick={goToPrevious}>
        <FontAwesome className="fa-solid fa-chevron-left" />
      </LeftArrow>

      <ViewGroup>
        <ViewTitle>{slides[currentIndex].view}</ViewTitle>

        <DotsContainerParent>
          <DotsContainerStyles currentIndex={currentIndex}>
            {slides?.map((slide, slideIndex) => {
              return (
                <DotStyle
                  key={slideIndex}
                  onClick={() => goToSlide(slideIndex)}
                  background={
                    slideIndex === currentIndex ? colors.blue3 : colors.grey2
                  }
                />
              );
            })}
          </DotsContainerStyles>
        </DotsContainerParent>

        {/* <Camera onClick={operator?.openCamera} disabled={disabled}>
          <FontAwesome color={colors.white} className={captureIcon[captureMode]} />
          <span className="white" style={{ textTransform: 'capitalize', fontWeight: '600' }}> {!uploadCompleted
            ? captureMode
            : `uploading...`}</span>
        </Camera> */}
      </ViewGroup>

      <RightArrow color={colors.blue3} dir="right" onClick={goToNext}>
        <FontAwesome className="fa-solid fa-chevron-right" />
      </RightArrow>
    </ArrowGroup>
  );
}

const Camera = styled("div")`
  cursor: pointer;
  margin-top: 10px;
  background-color: ${(props) =>
    (props.disabled && colors.grey2) || colors.blue3};
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;

    & span, & i {
      color: ${colors.black3} !important;
    }
  `}
  padding: 8px;
  border-radius: 6px;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -ms-flex-pack: start;
  justify-content: space-between;
  align-items: center;

  ${mobileScreen(`
    gap: 8px;
  `)}

  & span {
    ${mobileScreen(
      `
      font-size: 15px;
  `,
      screenSize.smallMobile
    )}
  }

  & i {
    ${mobileScreen(
      `
      font-size: 20px;
    `,
      screenSize.smallMobile
    )}
  }
`;

const ViewGroup = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.item {
    flex-grow: 1;
  }
  &.dot {
    background: ${colors.blue2};
  }
`;

const ViewTitle = styled("span")`
  color: ${colors.black};
  font-size: 15px;

  &::first-letter {
    text-transform: capitalize;
  }

  &:before {
    content: ${(props) => props.title || ""};
    position: relative;
  }
`;

const ArrowGroup = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 20px;
  align-items: flex-end;

  & * {
    flex: 1;
    transition: 0.8s;
  }
`;

const ArrowStyles = () => styled("button")`
  position: absolute;
  top: 50%;
  transform: translate(0; -50%);
  ${(props) => (props.dir === "left" && "left: 15%;") || "right: 15%;"}
  font-size: 30px;
  color: #10a9bb;
  display: block;
  z-index: 1;
  cursor: pointer;
  border: none;
  background: transparent;

  & i {
    color: ${(props) => props.color || "#10a9bb"};
  }

  ${mobileScreen(`
      position: relative;
    `)};
`;

const DotsContainerParent = styled("div")`
  display: block;
  width: 60px;
  overflow: hidden;
`;

const DotsContainerStyles = styled("div")`
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  align-items: center;
  height: 25px;
  width: 200px;
  margin-left: -${(props) => props.currentIndex * 20 - (props.currentIndex === 10 ? 40 : 20)}px;
  transition: 0.8s;
`;

const DotStyle = styled("div")`
  margin: 0 3px;
  cursor: pointer;
  font-size: 20px;
  height: 8px;
  width: 14px;
  border-radius: 10px;
  background-color: ${(props) => props.background};
`;
