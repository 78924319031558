import React from "react";
import { useEffect, useRef, useState } from "react";
import heic2any from "heic2any";
import convertBase64ToBlob from "../../helpers/convertBase64ToBlob";
import { allowedImgFormats, dbCollection, imgExt } from "../../data/configs";
import store from "../../assets/store";
import WebcamCapture from "../WebcamCapture";
import { uploadToCloudinary } from "../../utils/cloudinary";

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ state }) {
  const [blob, setBlob] = useState("");
  const {
    setUploadCompleted,
    setCaptures,
    captures,
    currentView,
    captureMode,
    userId,
    imgFile,
    setImgFile,
    setError,
    setWarning,
    hasToken,
    setUploadProgress,
    mappedCaptures,
    setMappedCaptures,
    aliasView,
    hasCompletedCapture,
    inputElement,
  } = state;

  const imgFilehandler = async (event) => {
    const userId = +store().get("user");
    const vehicleId = +store().get("vehicle");
    const inspected = +store().get("inspected");

    const file = event.target.files[0];

    if (!file) return;

    let blob = new Blob([file], { type: file.type });

    if (file.type === "image/heic") {
      blob = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5, // cuts the quality and size by half
      });
    }

    if (!["image/jpeg", "image/png", "image/heic"].includes(file.type)) {
      alert("Invalid file type. Please select a JPEG, PNG or HEIC image.");
      return;
    }

    if ((hasToken || +inspected) && vehicleId && !hasCompletedCapture) {
      try {
        setUploadCompleted(true);

        toDataURL(URL.createObjectURL(blob), async function (base64) {
          const compressed64 = await reduceImageQuality(base64, 0.2);
          const _ = base64.split(",")[1];
          console.log(
            { compressed64, base64 },
            " Comapre base64 to compressed64 $$$$$$$$$$$$$$$$$$$$$$$$$"
          );
          captures.push(compressed64);
          setCaptures(captures);
          setUploadCompleted(false);
          // uploadToCloudinary(file)
          //   .then((imgUrl) => {
          //     console.log(imgUrl, 'imgUrl');
          //     // captures.push(imgUrl);
          //     // setCaptures(captures);
          //     // setUploadCompleted(false);
          //     mappedCaptures.push({ alias: aliasView.alias, url: imgUrl })
          //   });
        });

        // captures.forEach(element => {
        //   uploadToCloudinary(file)
        //     .then((imgUrl) => {
        //       console.log(imgUrl, 'imgUrl');
        //       // captures.push(imgUrl);
        //       // setCaptures(captures);
        //       // setUploadCompleted(false);
        //     });
        // });
        // aliasView
        // setMappedCaptures(mappedCaptures)
        // console.log({ mappedCaptures }, 'mappedCaptures');
        // const reader = new FileReader();
        // reader.onloadend = () => {
        //   // const base64Image = reader.result;
        // // imageStore.__addImage({ view: window.currentView, url: base64Image, alias: window.currentAlias });
        // // captures.push(base64Image);
        // // setCaptures(captures);
        // // setUploadCompleted(false);
        // uploadToCloudinary(file)
        //   .then((imgUrl) => {
        //     console.log(imgUrl, 'imgUrl');
        //     // captures.push(imgUrl);
        //     // setCaptures(captures);
        //     // setUploadCompleted(false);
        //   });
        // };

        // reader.readAsDataURL(file);
        // setUploadCompleted(true);

        localStorage.removeItem("inspected");
      } catch (error) {
        console.log({ error });
        if (error.response.status === 400) {
          setError("Invalid url");
        }
      }
    }
  };

  return (
    <>
      <input
        accept="image/heic,image/*"
        id="fileid"
        type="file"
        ref={inputElement}
        capture="environment"
        onChange={imgFilehandler}
        hidden
      />
    </>
  );
}

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

function reduceImageQuality(base64, quality = 0.5) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64;

    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      canvas.toBlob(
        function (blob) {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            resolve(reader.result);
          };
        },
        "image/jpeg",
        quality
      );
    };

    img.onerror = function (error) {
      reject(error);
    };
  });
}
