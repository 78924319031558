import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useReducer,
} from "react";
import { useImageStore } from "./store";

const StorageContext = createContext();

export const StorageProvider = ({ children }) => {
  const { images, addImage, setCaptures } = useImageStore();

  // Image Store
  const __addImage = ({ view, url, alias }) => {
    addImage({ view, url, alias });
  };

  const __setImage = (captures) => {
    console.log("__setImage", captures);
    setCaptures(captures);
  };

  const __capturesAsArray = () => {
    return images.length ? images.map(([, value]) => value.url) : [];
  };

  const __getCaptures = () => {
    // JSON.parse(data).map(() => new Map(JSON.parse(data)))
    console.log({ images }, "///????////?????? __getCaptures");
    // return images.length
    //   ? images.map((value) => new Map(value))
    //   : [];
    return images;
  };

  // Helpers
  // const dataBeaver = (apiName) => {
  //   const data = providers[apiName];
  //   console.log({ apiName, data });
  //   return {
  //     filter(excludeFields = []) {
  //       return data.map((a) => {
  //         if (!Array.isArray(excludeFields) || !excludeFields.length) return a;
  //         const output = {}
  //         excludeFields.forEach((f) => {
  //           if (Object.hasOwnProperty.call(a, f)) {
  //             output[f] = a[f]
  //           }
  //         })
  //         return output;
  //       });
  //     }
  //   }
  // }

  const providers = {
    images,
    __addImage,
    __setImage,
    __capturesAsArray,
    __getCaptures,
    // dataBeaver
  };

  return (
    <StorageContext.Provider value={providers}>
      {children}
    </StorageContext.Provider>
  );
};

export const useStorageContext = () => {
  return useContext(StorageContext);
};
