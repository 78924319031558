import { fontSize } from "@mui/system";
import styled from "styled-components";
import colors from '../../assets/colors';
import { screenSize, mobileScreen, laptopScreen, desktopScreen, tabletScreen } from '../../assets/screens';


export const Container = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  ${props => props?.disabled && `
    & * {
      pointer-events: none;
      filter: grayscale(1);
    }
  `}

  ${laptopScreen(`
    max-width: 956px;
  `)};

  ${tabletScreen(`
    max-width: 700px;
  `)};
`;

export const Description = styled('div')`
    font-size: 16px;
    text-align: center;
    margin-bottom: 35px;
    
    & h3 {
        font-weight: 600;
        margin-bottom: 10px;
    }

    & span {
        color: ${colors.grey};
    }

    ${mobileScreen(`
      padding: 25px;
      padding-top: 0;
      margin-bottom: 0px;
    `)};
`;

export const SlideStyles = styled("div")`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
  `;

export const Button = styled('button')`
  border: none;
  background-color: ${props => props.background || 'transparent'};
  padding: ${props => props.paading || 0}px;
`;

export const Link = styled('a')`
  border: none;
  background-color: ${props => props.background || 'transparent'};
  padding: ${props => props.paading || 0}px;
  text-decoration: none;
`;

export const FontAwesome = styled("i")`
  color: ${props => props.color || colors.black};
  font-size: ${props => props.fontSize && props.fontSize || '25'}px;
`;

export const InvalidUrl = styled("div")`
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    font-size: 18px;
    background-color: ${colors.blue2};
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    gap: 8px;

    & a {
      // margin: 10px 0 0;
      color: ${colors.grey};
      font-size: 16px;
      font-weight: bold;
      background-color: ${colors.white};
      padding: 10px;
      border-radius: 3px;
    }

`;

export const Modal = styled("div")`
  position: absolute;
  transform: translate(-50%, -50%);
  transition: 1s;
  left: 50%;
  top: 50%;
  font-size: 18px;
  color: ${colors.black};
  height: 100%;
  width: 100%;
  z-index: 2;
  align-items: center;
  justify-content: center;
  display: ${props => props.state && 'flex' || 'none'};

  & * {
    z-index: 3;
  }

   & header, & footer {
    padding: 5px 10px;
  }

  &::before {
    content: '';
    position: absolute;
    height: 200%;
    width: 100%;
    opacity: 0.5;
    background-color: ${colors.black};
  }
`;

export const ModalBody = styled("div")`
  overflow: hidden;
  position: relative;
  height: 350px;
  width: 500px;
  background-color: ${colors.white};
  border-radius: 6px;
  display: block;

  ${props => props.center && `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${props.dir || 'row'};
    gap: ${props.spacing || '0'}px;
  `}

  ${mobileScreen(`
    width: 90%;
    height: 250px;
`)};
`;

export const ModalHeader = styled("header")`
  position: relative;
  height: 20px;
  background-color: ${colors.transparent};
  display: block;
`;

export const ModelText = styled("span")`
  position: relative;
  height: 20px;
  font-weight: bold;
  font-size: ${props => props.fontSize || '16'}px;
  color: ${props => props.color || colors.black};
`;

export const CameraScreen = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.white};
  display: none;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 100;

  & video {
    // margin-top: 50px;
    height: 300px;
    width: 100%;
  }
`;

export const Middle = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Garage = styled("div")`
  overflow: scroll;
  display: flex;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none; 
  transition: .8s;
  height: 190px;
  align-items: center;

  ::-webkit-scrollbar {
    display: none;
   }

  ${tabletScreen(`
    justify-content: flex-start;
    `)};

`;

export const LottieContainer = styled("div")`
    height: 100%;
    position: fixed;
    transform: translate(-50%, 50%);
    left: 50%;

  ${tabletScreen(`
      justify-content: flex-start;
  `)};
`;

export const CapturesGroup = styled("div")`
  display: flex;
  margin: 20px 0;
  width: 900px; 
  transition: .8s;
  column-gap: 10px;

    ${props => props.currentIndex >= 7 && `
        @media (min-width: 1024px) {
            margin-left: -${props.currentIndex * 200 - (props.currentIndex === 10 ? 400 : 20)}px;
        }
    `}

    ${props => props.currentIndex >= 5 && `
        @media (max-width: 768px) {
            margin-left: -${props.currentIndex * 85 - (props.currentIndex === 10 ? 105 : 20)}px;
        }

        @media (max-width: 425px) {
            margin-left: -${props.currentIndex * 70 - (props.currentIndex === 10 ? 200 : 20)}px;
        }
    `}

    ${props => props.currentIndex >= 4 && `
        @media (max-width: 375px) {
            margin-left: -${props.currentIndex * 70 - (props.currentIndex === 10 ? 120 : 20)}px;
        }
    `}

    ${props => props.currentIndex >= 3 && `
        @media (max-width: 320px) {
            margin-left: -${props.currentIndex * 70 - (props.currentIndex === 10 ? 40 : 30)}px;
        }
    `}

    justify-content: flex-start;
    // justify-content: ${props => props.currentIndex && 'flex-start' || 'center'};
    padding: 0 10px !important;

    ${laptopScreen(`
        padding: 0;
        gap: 20px;
        width: 800px; 
    `)};

    ${mobileScreen(`
        padding: ${props => !props.currentIndex && '0 10' || '0'}px; 
        ${props => props.currentIndex && 'justify-content: center;'}
        flex-direction: row !important;
    `, screenSize.smallMobile)};
`;