import React, { useEffect, useState, useRef } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Gallery from "../Gallery";
import Capture, { EmptyCaptures } from "../Gallery/Capture";
import styled from "styled-components";
import { Container, Description, CapturesGroup, Garage } from "../Bootstrap";
import { vehicleSides, bikeSides } from "../../data/vehicle";
import ImageProcessor from "../ImageProcessor";
import { mobileScreen } from "../../assets/screens";
import {
  captureMode as mode,
  captureDesc,
  baseURL,
  balloonURL,
  dbCollection,
  pages,
} from "../../data/configs";
import axios from "../../lib/axios";
import NotyStack from "../Bootstrap/NotyStack";
import getLocation from "../../helpers/getLocation";
import { useStorageContext } from "../../core/storage-provider";
import { uploadToCloudinary } from "../../utils/cloudinary";
import Claims from "../Sections/claims";
import LottieLoader from "../LottieLoader";

const garageState = {
  captureIndex: 0,
  captureCount: 0,
  captures: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (parent) {
  const imageStore = useStorageContext();
  const {
    currentIndex,
    setCurrentIndex,
    setWarning,
    warning,
    setError,
    error,
    setSubmitted,
    setOpenModal,
    isInvalidToken,
    isClaims,
    page,
    setPage,
    pageCount,
    setPageCount,
  } = parent.operators;

  const hasToken = window.location.href.split("?token=")[1]?.length || null;

  const webcamRef = useRef(null);
  const [state, setState] = useState(garageState);
  const [userId, setUserId] = useState(80);
  const [loaded, setLoaded] = useState(false);
  const [isUploadingCap, setIsUploadingCap] = useState(false);
  const [capUploadCount, setCapUploadCount] = useState(0);
  const [imgData, setImgData] = useState("");
  const [captures, setCaptures] = useState(imageStore.__capturesAsArray());
  const [mappedCaptures, setMappedCaptures] = useState([]);
  const [aliasView, setAliasView] = useState("");
  const [percent, setPercent] = useState(0);
  const [captureMode, setCaptureMode] = useState(mode.camera);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [clear, setClear] = useState(false);
  const [currentView, setCurrentView] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [camera, setCamera] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [imgFile, setImgFile] = useState();
  const [capIndex, setCapIndex] = useState(0);
  const inputElement = useRef(null);
  const [captureSides, setCaptureSides] = useState(vehicleSides);

  if (!hasToken) {
    setError("Invalid url");
  }

  const captureSize = captures.length;
  const percentage =
    captureSize === 10
      ? 90
      : Math.ceil(
          Math.ceil(((captureSize / captureSides?.length) * 100) / 10)
        ) * 10;

  const hasCompletedCapture = captureSize === captureSides?.length;

  const vehicleReg = new URLSearchParams(
    window.location.href.split("?")[1]
  ).get("vehicle-reg");

  const vehicleType = new URLSearchParams(
    window.location.href.split("?")[1]
  ).get("vehicle-type");

  const openCamera = () => {
    inputElement.current.click();
  };

  const saveCaptures = async () => {
    const mappedCaptures = [...state.captures].reduce(
      (acc, [, { view, ...capture }], index) => {
        acc[capture.alias] = capture.url;
        return acc;
      },
      {}
    );
    let uploadCount = 0;
    const captures = {};
    setIsUploadingCap(true);
    for (const side in mappedCaptures) {
      if (Object.hasOwnProperty.call(mappedCaptures, side)) {
        const base64 = mappedCaptures[side];
        const { secure_url, public_id } = await uploadToCloudinary(base64);
        Object.assign(captures, {
          [side]: { url: secure_url, publicId: public_id },
        });
        uploadCount++;
        setCapUploadCount(uploadCount);
      }
    }
    setIsUploadingCap(false);

    const authentic = localStorage.getItem("authentic");

    setIsRequest(true);
    const request = axios("key-api");

    if (hasToken && +authentic) {
      (async () => {
        try {
          const token = new URLSearchParams(
            window.location.href.split("?")[1]
          ).get("token");
          const isBalloon =
            new URLSearchParams(window.location.href.split("?")[1]).get(
              "platform"
            ) === "balloon";
          const baseUrl = `${
            isBalloon ? balloonURL : baseURL
          }/${dbCollection}/captures/${token}`;

          const payload = {
            ...userLocation,
            ...captures,
          };
          const resp = await request.post(baseUrl, payload);
          setOpenModal(true);
          setSubmitted(true);
          clearCaptures();
        } catch (error) {
          if (error.response.data.message && !isInvalidToken) {
            setError(error.response.data.message);
          }
          console.error(error);
        }
        setIsRequest(false);
      })();
    }
  };

  const clearCaptures = () => {
    setCaptures([]);
    setClear(true);
  };

  useEffect(() => {
    const location = getLocation();
    setUserLocation(location);
    setLoaded(true);
  }, [loaded]);

  useEffect(() => {
    if (typeof window !== "undefined" && vehicleType === "motorcycle") {
      setCaptureSides(bikeSides);
    } else {
      setCaptureSides(vehicleSides);
    }
  }, [vehicleType]);

  useEffect(() => {
    if (percent >= captureSides?.length) return;
    setCurrentView(captureSides?.[currentIndex]?.view);
    setPercent(captureSize);
    const __captures = new Map(
      captures.map((url, i) => {
        const side = captureSides?.[isClaims ? currentIndex : i];
        const view = side.view;
        const alias = side.alias;
        setAliasView({ alias, view });
        return [view, { url, view, alias }];
      })
    );
    setState({
      clear,
      setClear,
      captureCount: captureSize,
      uploadProgress,
      uploadCompleted,
      setUploadProgress,
      clearCaptures,
      setUploadCompleted,
      setCurrentView,
      captureMode,
      setImgData,
      webcamRef,
      openCamera,
      camera,
      isInvalidToken,
      setCamera,
      setWarning,
      captures: __captures,
    });

    imageStore.__setImage(__captures);
  }, [uploadCompleted]);

  const Captures = ({ images }) =>
    [...images].map((store, i) => {
      const index = i + 1 >= captureSides?.length ? i : i + 1;
      setCapIndex(index);
      return (
        <Capture
          currentIndex={currentIndex}
          key={i}
          state={state}
          src={store[1]?.url}
          side={store[1]?.view}
          captureSides={captureSides}
        />
      );
    });

  const GarageComp = () => (
    <Garage currentIndex={currentIndex}>
      <CapturesGroup currentIndex={currentIndex}>
        {(!captureSize && (
          <EmptyCaptures
            captureSides={captureSides}
            currentIndex={currentIndex}
          />
        )) || <Captures images={[...state.captures]} />}
      </CapturesGroup>
    </Garage>
  );

  let headerSubtext = {
    vehicleInfo: "Select the vehicle you are making a claim on.",
    driverInfo:
      "We would like to ask some information about the vehicle involved",
    incidentInfo: "We would like to ask some information about the incident",
  };

  let headerText = {
    vehicleInfo: "Driving Question",
    driverInfo: "Driving Question",
    incidentInfo: "Driving Question",
    vehicleCapture:
      vehicleType != "motorcycle" ? "Vehicle Capture" : "Bike Capture",
  };

  headerSubtext = headerSubtext[page];
  headerText = headerText[page];

  return isUploadingCap ? (
    <LottieLoader />
  ) : (
    <Container fluid={true} className="p-0">
      {error && <NotyStack message={error} variant="error" />}
      {warning && <NotyStack message={warning} variant="warning" />}

      <Header
        percent={percentage}
        isClaims={pages.vehicleCapture !== page}
        headerSubtext={headerSubtext}
        headerText={headerText}
      />

      {page === pages.vehicleCapture ? (
        <Container>
          <Description>
            {!isClaims && (
              <h3 className="text-center fs-2" style={{ fontWeight: "400" }}>
                {captureDesc[captureMode]} of your{" "}
                {vehicleType != "motorcycle" ? "car" : "bike"}
              </h3>
            )}
            <h3
              className="text-center"
              style={{ fontWeight: "400", fontSize: "16px" }}
            >
              {vehicleType != "motorcycle" ? "Vehicle" : "Bike"} plate -{" "}
              {vehicleReg}
            </h3>

            <SubTitle>
              {!isClaims
                ? "Use the image below as a guide, the red box shoud be the area of focus"
                : headerSubtext}
            </SubTitle>
          </Description>

          <ImageProcessor
            state={{
              captures,
              setUploadCompleted,
              setCaptures,
              userId,
              setUploadProgress,
              currentView,
              error,
              setError,
              setWarning,
              captureMode,
              hasToken,
              imgFile,
              currentIndex,
              imgData,
              mappedCaptures,
              setMappedCaptures,
              aliasView,
              setAliasView,
              hasCompletedCapture,
              inputElement,
            }}
          />

          <Gallery
            capIndex={capIndex}
            setCapIndex={setCapIndex}
            imageInputs={state}
            setCurrentIndex={setCurrentIndex}
            currentIndex={currentIndex}
          />
          <GarageComp />
        </Container>
      ) : (
        <Claims page={page} />
      )}
      <Footer
        pageAction={{ page, setPage, pageCount, setPageCount }}
        disabled={!hasCompletedCapture}
        saveCaptures={saveCaptures}
        clearCaptures={clearCaptures}
        action={{ setIsRequest, isRequest }}
        currentIndex={currentIndex}
      />
    </Container>
  );
}

const SubTitle = styled("span")`
  font-size: 14px;
  ${mobileScreen(`
    font-size: 14px;
  `)}
`;
