// eslint-disable-next-line import/no-anonymous-default-export

// eslint-disable-next-line import/no-anonymous-default-export
export default function(key = 'capture') {
  const data = localStorage.getItem(key);
  return {
    getCaptures() {
      return data 
        ? JSON.parse(data).map(() => new Map(JSON.parse(data)))
        : [];
    },
    getCapturesAsArray() {
      return data ? Array.from(JSON.parse(data).values()).map(value => value[1].url) : []
    },
    setCaptures(data) {
      data.size && localStorage.setItem(key, JSON.stringify([...data]));
    },
    clearCaptures() {
      localStorage.clear();
    },
    get(key) {
      return localStorage.getItem(key);
    },
    size: data && JSON.parse(data).map(() => new Map(JSON.parse(data))).length || 0
  }
}