import React, { useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";
import "./App.css";
import Main from "./components/Main";
import { InvalidUrl, Link } from "./components/Bootstrap";
import setToken from "./utils/set-token";
import LottieLoader from "./components/LottieLoader";
import SuccessModal from "./components/Modals/success.modal";
window.pageCountt = 1;

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [isClaims, setIsClaims] = useState(false);
  const [error, setError] = useState();
  const [warning, setWarning] = useState();
  const [openModel, setOpenModal] = useState(false);
  const [page, setPage] = useState(null);
  const [imgUrl, setImgUrl] = useState("");

  const hasToken = window.location.href.split("?token=")[1]?.length || null;
  let pageCount = 0;
  const setPageCount = (n) => window.pageCountt++;

  useEffect(() => {
    try {
      const fetchImage = async () => {
        try {
          const response = await import("./completed.gif");
          setImgUrl(response.default);
        } catch (err) {
          console.error(err);
        }
      };

      fetchImage();
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    setToken({
      setIsInvalidToken,
      setError,
      submitted: false,
      setIsClaims,
      setPage,
    })
      .then((d) => console.log(d))
      .catch((e) => console.error(e));
  }, [error]);

  const InvalidScreen = () => (
    <InvalidUrl>
      <h4>Invalid URL</h4>
      <Link href="/">Go home</Link>
    </InvalidUrl>
  );

  const operators = {
    setIsInvalidToken,
    submitted,
    setSubmitted,
    setOpenModal,
    openModel,
    setWarning,
    warning,
    setError,
    error,
    isInvalidToken,
    isClaims,
    setIsClaims,
    page,
    setPage,
    pageCount,
    setPageCount,
    currentIndex,
    setCurrentIndex,
  };

  const text = isClaims ? "Claims completed." : "Pre loss completed.";

  const subText = isClaims
    ? "Your payout is on its way. Please check your email for more information"
    : "Vehicle images uploaded successfully. Thank you for choosing ETAP.";

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {openModel ? (
        <SuccessModal url={imgUrl} text={text} subText={subText} />
      ) : (
        <div className="App">
          {!page ||
          !localStorage.getItem("user") ||
          localStorage.getItem("inspected") ? (
            <LottieLoader />
          ) : hasToken ? (
            <Main operators={{ ...operators }} />
          ) : (
            <InvalidScreen />
          )}
        </div>
      )}
    </SnackbarProvider>
  );
}

export default App;
