import { css } from "styled-components";
export const screenSize = {
  smallMobile: "375px",
  mobile: "425px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "2560px",
}

export const mobileScreen = (inner, size = screenSize.mobile) => css`
  @media (max-width: ${size}) {
    ${inner};
  }
`;
export const tabletScreen = (inner, size = screenSize.tablet) => css`
  @media (max-width: ${size}) {
    ${inner};
  }
`;
export const desktopScreen = (inner, size = screenSize.laptop) => css`
  @media (min-width: ${size}) {
    ${inner};
  }
`;
export const laptopScreen = (inner, size = screenSize.tablet) => css`
  @media (min-width: ${size}) {
    ${inner};
  }
`;