import React from "react";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";
import colors from "../../assets/colors";
import { Button } from "../Bootstrap";
import { laptopScreen, screenSize } from "../../assets/screens";
import { pages } from "../../data/configs";
// let pageCountt = 1;
// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
  pageAction,
  disabled = true,
  clearCaptures,
  saveCaptures,
  action,
}) {
  const { setIsRequest, isRequest } = action;
  let { page, setPage, pageCount, setPageCount } = pageAction;

  const override = {
    display: "block",
    margin: "0 auto",
    height: "13px",
    width: "13px",
  };

  // const goToNext = () => location.
  // pageCount++

  const goToNext = () => {
    // setPageCount()
    setPage(Object.keys(pages)[window.pageCountt++]);
  };

  const goToPrev = () => {
    // setPageCount()
    setPage(Object.keys(pages)[window.pageCountt--]);
    // setPage(Object.keys(pages)[window.pageCountt === 1 ? 0 : window.pageCountt--])
  };

  const isClaims = page !== pages.vehicleCapture;
  const disabledSub = isClaims && disabled ? !isClaims : disabled;

  return (
    <Footer className="standard-width">
      <FooterChild style={{ justifyContent: "flex-start" }}>
        <FooterButton
          style={
            {
              // position: 'absolute',
              // left: '15px',
              // padding: '15px',
              // paddingLeft: '20px'
            }
          }
          type="button"
          onClick={clearCaptures}
        >
          Cancel
        </FooterButton>
        {/* type="button" onClick={isClaims ? goToPrev : clearCaptures}>{isClaims && 'Prev' || 'Clear'}</FooterButton> */}
      </FooterChild>

      <FooterChild style={{ justifyContent: "flex-end" }}>
        <FooterButton
          type="button"
          onClick={isClaims ? goToNext : saveCaptures}
          disabled={disabledSub}
          color={!disabled ? colors.white : null}
          style={{
            // position: 'absolute',
            // right: '30px',
            // borderRadius: '5px',
            backgroundColor: !disabled
              ? colors.blue3
              : (isClaims && colors.blue2) || colors.grey2,
          }}
        >
          {isRequest ? (
            <ClipLoader
              color={colors.white}
              loading={true}
              cssOverride={override}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            (isClaims && "Next") || "Submit"
          )}
        </FooterButton>
      </FooterChild>
    </Footer>
  );
}

const Footer = styled("div")`
  //   display: flex;
  //   justify-content: space-between;
  //   border-top: 2px solid #dadada;
  //   padding: 10px;
  //   position: relative;
  //   bottom: 0;
  //   left: 0;

  //   display: flex;
  //   justify-content: space-between;
  //   border-top: 2px solid #dadada;
  //   padding: 10px;
  //   position: absolute;
  //   bottom: 0px;
  // //   left: 0;
  //   width: 100%;
  //   height: 20px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 2px solid #dadada;
  padding: 10px 20px;
  /* position: absolute; */
  bottom: 0px;
  /* width: 100%; */
`;

const FooterButton = styled(Button)`
  cursor: pointer;
  font-size: 16px;
  color: ${(props) => props.color || colors.black} !important;
  // font-weight: 500;
  border: none;
  background-color: ${(props) => props.backgroundColor || "transparent"};
  border-radius: 8px;
  padding: 15px 30px;
`;

const FooterChild = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
`;
