import React, { useEffect, useState } from "react";
import NavigateSides from "./NavigateSides";
import Slider from "./Slider";
import { bikeSides, vehicleSides } from "../../data/vehicle";

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
  imageInputs,
  setCapIndex,
  capIndex,
  setCurrentIndex,
  currentIndex,
}) {
  const [captureSides, setCaptureSides] = useState(vehicleSides);
  const vehicleType = new URLSearchParams(
    window.location.href.split("?")[1]
  ).get("vehicle-type");

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? captureSides.length - 1 : currentIndex - 1;
    goToSlide(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === captureSides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    goToSlide(newIndex);
  };
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    goToSlide(capIndex);
  }, [capIndex]);

  useEffect(() => {
    if (imageInputs.clear) {
      goToSlide(0);
      imageInputs.setClear(false);
    }
  }, [imageInputs.clear]);

  useEffect(() => {
    if (typeof window !== "undefined" && vehicleType === "motorcycle") {
      setCaptureSides(bikeSides);
    } else {
      setCaptureSides(vehicleSides);
    }
  }, [vehicleType]);
  const operator = {
    ...imageInputs,
    setCurrentIndex,
    currentIndex,
    goToNext,
    goToPrevious,
    goToSlide,
    slides: captureSides,
  };
  return (
    <>
      <Slider operator={operator} />
      <NavigateSides operator={operator} />
    </>
  );
}
