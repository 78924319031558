// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
  const location = {};
  if ("geolocation" in navigator) {
    navigator.geolocation.watchPosition(function (position) {
      location['latitude'] = position.coords.latitude;
      location['longitude'] = position.coords.longitude;
    });
    navigator.geolocation.getCurrentPosition(function (position) {
      location['latitude'] = position.coords.latitude;
      location['longitude'] = position.coords.longitude;
    });
  } else {
    alert('Browser Not Supported. Try updating your browser or use a Chrome browser instead');
  }
  return location;
}