import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { pages } from '../../data/configs';

export const weather = [
  {
    value: 'sunny',
    label: 'Sunny',
  },
  {
    value: 'cloudy',
    label: 'Cloudy',
  },
  {
    value: 'R=rainy',
    label: 'Rainy',
  },
  // {
  //   value: 'JPY',
  //   label: '¥',
  // },
];

const speed = [
  {
    value: '0',
    label: '0km/hr',
  },
  {
    value: '1',
    label: '1-10km/hr',
  },
  {
    value: '2',
    label: '11-25km/hr',
  },
  {
    value: '3',
    label: '26-40km/hr',
  },
];
const vehicles = [
  {
    value: '12233',
    label: 'Suzuki Alto / 12233',
  },
]
// eslint-disable-next-line import/no-anonymous-default-export
export default function({page = pages.incidentInfo}) {
  const VehicleInfo = () => (
    <div style={{'height': '100%'}}>
     <TextField
        id="filled-select-currency"
        select
        label="Choose the vehicle"
        // defaultValue='0'
        InputLabelProps={{
          shrink: true,
        }}
      >
        {vehicles.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
  </div>
  )


  const IncidentIfo = () => (
    <div>
    <TextField
      id="outlined-multiline-flexible"
      label="When did the incident occur?"
      multiline
      maxRows={6}
      InputLabelProps={{
        shrink: true,
      }}
    />
    <TextField
      id="outlined-multiline-flexible"
      label="When time was it?"
      multiline
      maxRows={6}
      InputLabelProps={{
        shrink: true,
      }}
    />
    <div>
      <TextField
        id="filled-select-currency"
        select
        label="How fast were you moving?"
        // defaultValue='0'
        InputLabelProps={{
          shrink: true,
        }}
      >
        {speed.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

    </div>
    <div>
      <TextField
        id="filled-select-currency"
        select
        label="What was the weather condition?"
        // defaultValue="sunny"
        InputLabelProps={{
          shrink: true,
        }}
      >
        {weather.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

    </div>

    <div>
      <TextField
        id="outlined-multiline-flexible"
        label="Describe what happened"
        placeholder='Briefly tell us when it happened including:
        where, what happend and non vehicle
        property damage i.e parked cars, buiding
        wall etc.'
        multiline
        maxRows={6}
        sx={{
          height: '200px',
          fontSize: '8px'
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>

  </div>
  )

  const DriverInfo = () => (
    <>
    <div>
    <TextField
      id="outlined-multiline-flexible"
      label="Enter driver's nanme"
      multiline
      maxRows={6}
      InputLabelProps={{
        shrink: true,
      }}
    />
    </div>
      <div>
      <TextField
        id="outlined-multiline-flexible"
        label="Contact number"
        multiline
        maxRows={6}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
    <div>
      <TextField
        id="outlined-multiline-flexible"
        label="Insurance policy number"
        multiline
        maxRows={6}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
    </>
  )

  return (
    <Container maxWidth="sm">
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { marginY: 1.6, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      {
        page === pages.vehicleInfo && <VehicleInfo /> || page === pages.incidentInfo && <IncidentIfo /> || page === pages.driverInfo && <DriverInfo />
      }
    </Box>
  </Container>
  )
}