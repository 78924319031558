import axios from "axios";
import * as Jimp from "jimp";
import { cloudinaryConfig, imgExt } from "../data/configs";

export const uploadToCloudinary = async (file) => {
  // return new Promise((resolve, reject) => {
  //   sharp(file)
  //     .resize(500, 500)
  //     .toBuffer()
  //     .then(async (buffer) => {
  //       const formData = new FormData();
  //       // formData.append('file', file);
  //       formData.append('file', buffer, { filename: `${window.currentAlias}.${imgExt}` })
  //       formData.append('upload_preset', cloudinaryConfig.uploadPreset);
  //       formData.append('api_key', cloudinaryConfig.apiKey);

  //       const axiosConfig = {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       };

  //       const { data } = await axios
  //         .post(cloudinaryConfig.url, formData, axiosConfig);

  //         resolve(data.secure_url);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });

  // return new Promise((resolve, reject) => {
  //   Jimp.read(file)
  //   .then((image) => {
  //     // Resize the image to a maximum width and height of 500 pixels
  //     image.resize(500, Jimp.AUTO);

  //     // Convert the image to a Buffer and create a FormData object
  //     image.getBufferAsync(Jimp.AUTO)
  //       .then( async (buffer) => {
  //          const formData = new FormData();
  //       // formData.append('file', file);
  //       formData.append('file', buffer, { filename: `${window.currentAlias}.${imgExt}` })
  //       formData.append('upload_preset', cloudinaryConfig.uploadPreset);
  //       formData.append('api_key', cloudinaryConfig.apiKey);

  //       const axiosConfig = {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       };

  //       const { data } = await axios
  //         .post(cloudinaryConfig.url, formData, axiosConfig);
  //         resolve(data.secure_url);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   })
  // });

  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", cloudinaryConfig.uploadPreset);
    formData.append("api_key", cloudinaryConfig.apiKey);

    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      cloudinaryConfig.url,
      formData,
      axiosConfig
    );

    return data;
  } catch (error) {
    console.error("Image upload error:", error);
  }
};
