export const captureUrl = process.env.REACT_APP_VEHICLE_SIDES_IMGS_URL;
export const dbCollection = "vehicle-inspections";
export const baseURL = process.env.REACT_APP_BACKEND_URL;
export const balloonURL = process.env.REACT_APP_BALLOON_URL;
export const allowedImgFormats = "png,jpg,jpeg,heic";
export const imgExt = "jpg";

export const captureMode = {
  camera: "camera",
  fileupload: "fileupload",
};

export const captureIcon = {
  camera: "fa fa-camera",
  fileupload: "fa fa-cloud-arrow-up",
};

export const captureDesc = {
  camera: "Take a picture",
  fileupload: "Upload an image",
};

export const firebaseConfig = {
  apiKey: "AIzaSyAEXf2rRCdtWj2p_eawBPMi0xZoXJCQuWU",
  authDomain: "balloon-vehicle-capture.REACT_APP_firebaseapp.com",
  projectId: "balloon-vehicle-capture",
  storageBucket: "balloon-vehicle-capture.appspot.com",
  messagingSenderId: "686409199569",
  appId: "1:686409199569:web:556d4d2c36e7cdd9b4baf0",
  measurementId: "G-1F3ZNJY2C1",
};

export const cloudinaryConfig = {
  apiKey: "673661589855615",
  url: "https://api.cloudinary.com/v1_1/etap/image/upload",
  uploadPreset: "zfrivduh",
};

export const pages = {
  vehicleInfo: "vehicleInfo",
  incidentInfo: "incidentInfo",
  driverInfo: "driverInfo",
  vehicleCapture: "vehicleCapture",
};
