const colors = {
  blue: '#99f5ff',
  blue2: '#00d6f3',
  blue3: '#03bdc3',
  black: '#121212b8',
  black3: '#929292',
  grey: '#7C7C7C',
  grey2: '#ececec',
  green: '#4FBF26',
  white: '#fff',
  transparent: 'transparent',
}

export default colors;