import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../assets/colors";
import {
  mobileScreen,
  screenSize,
  // laptopScreen,
  // desktopScreen,
  // tabletScreen,
} from "../../assets/screens";

// eslint-disable-next-line import/no-anonymous-default-export
function Capture({ key, src, side, content, currentIndex, captureSides }) {
  const [blob, setBlob] = useState(src);
  const ImageCapture = Unknown((src && "img") || "div");

  return (
    <CaptureGroup hasImg={src}>
      <CaptureTitle>{(src && side) || ""}</CaptureTitle>

      <ImageCapture
        currentView={captureSides?.[currentIndex]?.view}
        className="capture"
        src={blob}
        content={content}
      />
    </CaptureGroup>
  );
}

export default Capture;
export const EmptyCaptures = ({ currentIndex, captureSides }) =>
  new Array(captureSides.length).fill(
    <Capture currentIndex={currentIndex} content={""} />
  );

const CaptureGroup = styled("div")`
  display: block;
  text-align: center;
  flex-direction: column;
  width: ${(props) => (props.hasImg && "90") || "85"}px;
  align-items: center;
  margin: 0 6px;
  transition: 1s;

  ${mobileScreen(`
    margin: 0;  
  `)}
`;

const Unknown = (element) => styled(element)`
  display: inline-block;
  border-radius: 50%;
  // border-radius: 5px;
  height: 100px;
  width: 100px;
  // max-width: 100px;
  // border: ${(props) => (props.src && `2px solid ${colors.blue3}`) || "none"};
  border: 2px solid #00bdc224;
  background-color: ${colors.grey2};
  object-fit: cover;
  background-size: cover;
  background-position: center;
  margin-top: 10px;
  position: relative;

  &::before {
    position: absolute;
    content: "${(props) => props.content}";
    transform: translate(-50%, -50%);
    font-size: 10px;
    color: ${colors.grey};
    opacity: 0.3;
    top: 50%;
    left: 50%;
  }

  ${mobileScreen(`
    height: 75px;
    width: 75px;
    border-radius: 50%;

    &::before {
      font-size: 8px;
    }
  `)};

  ${mobileScreen(
    `
    height: 70px;
    width: 70px;
  `,
    screenSize.smallMobile
  )};
`;

const CaptureTitle = styled("span")`
  font-size: 11px;
  text-transform: capitalize;
  display: block;
  font-weight: 300;
  font-size: #616161;
`;
