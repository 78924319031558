import React from "react";
import styled from "styled-components";
import colors from "../../assets/colors";
import {
  screenSize,
  mobileScreen,
  laptopScreen,
  desktopScreen,
  tabletScreen,
} from "../../assets/screens";
import { FontAwesome } from "../Bootstrap";
import Loader from "../Loader";

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ percent, isClaims, headerSubtext, headerText }) {
  return (
    <Header>
      <HeaderText className="fs-1">
        {headerText}
        {/* {
          !isClaims
            ? 'Vehicle Capture'
            : 'Drving Question'
        } */}
      </HeaderText>

      {!isClaims && <ProgressMobile percent={percent} />}

      <h5 style={{ margin: "15px 0", padding: "0 10px", color: "#a2a2a2" }}>
        {headerSubtext}
      </h5>

      {/* <ProgressDesktop>

        <Loader
          stroke={colors.blue3}
          strokeWidth="10"
          width={80}
          sqSize="200"
          percentage={percent} />

      </ProgressDesktop> */}
    </Header>
  );
}

const HeaderText = styled("h5")`
  // position: absolute;
  // top: 0;
  // left: 30px;
  color: ${colors.black};
`;

const ProgressDesktop = styled("div")`
  position: absolute;
  top: 0;
  left: 30px;

  ${mobileScreen(`
      display: none;
  `)}
`;
const Header = styled("div")`
  text-align: center;
  padding-bottom: 5px;
  margin-top: 20px;
  padding: 0 2px;
`;

const ProgressMobile = styled("div")`
  background-color: #00bdc224;
  width: 100%;
  height: 18px;
  display: none;
  position: relative;
  margin: 15px 0;
  color: ${(props) => (props.percent >= 50 ? "white" : "black")};

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    background-color: ${colors.blue3};
    height: 100%;
    width: ${(props) => (props.percent ? props.percent : 0)}%;
  }

  &:before {
    content: "${(props) => (props.percent ? props.percent : 0)}%";
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  ${mobileScreen(`
    display: block;
  `)};
`;
