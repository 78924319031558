
import {create} from "zustand";

// Image store
export const useImageStore = create((set) => ({
  images: [], // The array in state
  addImage: ({view, url, alias}) =>
    set((state) => ({ images: [...state.images, url] })), 
  setCaptures: (mappedCapture) =>
    set((state) => ({ images: [...state.images, ...mappedCapture] })), // Update the array by adding an image
  // update: (courseId, updatedCourse) =>
  //   set((state) => ({
  //     images: state.images.map((course) =>
  //       course.id === courseId ? { ...course, ...updatedCourse } : course
  //     ),
  //   })), // Update an course in the array
  // remove: (courseId) =>
  //   set((state) => ({
  //     images: state.images.filter((course) => course.id !== courseId),
  //   })), // Remove an course from the array
}));

// const settings = { customization: new Map() }
// OrgSettings store
// export const useOrgSettingsStore = create((set) => ({
//   settings, // The array in state
//   customize: (object) =>
//     set((state) => {
//       const option = 'customization';

//       if (isHashmap(!object)) { // Must be an object.
//         console.error('useOrgSettingsStore::customize accepts only hashMaps Or JS object');
//         return;
//       }

//       // localStorage.setItem('settings', JSON.stringify({
//       //   color: '#FF0000',
//       //   font: '30px bold'
//       // }))
//       setMap(state, option)(object);
//       return { 
//         settings: state.settings
//         }
//     })
// }));
