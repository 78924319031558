import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import styled from "styled-components";
import colors from "../../assets/colors";
import { laptopScreen, mobileScreen, screenSize } from "../../assets/screens";
import { captureUrl } from "../../data/configs";
import { FontAwesome } from "../Bootstrap";
// import reduceImageQuality from '../../helpers/reduceImageQuality';

const videoConstraints = {
  width: "100%",
  height: "100%",
  facingMode: "user",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ operator }) {
  const imageName = operator?.slides[operator?.currentIndex].url;
  const [blob, setBlob] = useState(imageName);
  const [image, setImage] = useState(null);

  window.currentView = operator?.slides[operator?.currentIndex].view;
  window.currentAlias = operator?.slides[operator?.currentIndex].alias;
  window.currentIndex = operator?.currentIndex;

  // const openMediaSource = (e) => {
  //   operator?.setCamera(true);

  //   if (!localStorage.getItem("user") || !localStorage.getItem("vehicle")) {
  //     window.location.reload();
  //   }
  // };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`/public/imgs/${imageName}`);
        setImage(response.default);
      } catch (err) {
        console.error(err);
      }
    };

    fetchImage();

    // reduceImageQuality(imageName, 200, 800, 600, (reducedImage) => {
    //   // console.log({ reducedImage });
    //   setBlob(reducedImage)
    // }, true);
  }, [blob, imageName]);

  const SlideStyles = styled("div")`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `;

  const SlideStylesWidthBackground = styled(SlideStyles)`
    cursor: pointer;
    background-image: url(${image});
  `;

  return (
    <ContainerStyles>
      <ClearBtn onClick={operator.clearCaptures}>
        <FontAwesome
          className="fa-solid fa-xmark"
          style={{ color: "crimson" }}
        />
      </ClearBtn>
      <SlideStyles>
        <SlideStylesWidthBackground onClick={operator?.openCamera} />
      </SlideStyles>
    </ContainerStyles>
  );
}

const ClearBtn = styled("button")`
  all: unset;
  cursor: pointer;
  display: none;
`;

const ContainerStyles = styled("div")`
  width: 300px;
  height: 200px;
  margin: 0 auto;

  ${laptopScreen(`
    width: 55%;
    // height: 300px;
  `)};
`;

const sliderStyles = {
  position: "relative",
  height: "100%",
};

const DotsContainerStyles = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
`;

const DotStyle = styled("div")`
  margin: 0 3px;
  cursor: pointer;
  font-size: 20px;
  background: grey;
  height: 10px;
  width: 40px;
  border-radius: 10px;
`;
