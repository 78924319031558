import React from 'react';
import ReactDOM from 'react-dom/client';
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import { StorageProvider } from './core/storage-provider';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Claims = () => {
  return(
    <div>
      Calims
    </div>
  )
}
const Dashboard = () => {
  return(
    <div>
      Dashboard
    </div>
  )
}
root.render(
  <React.StrictMode>
    <StorageProvider>
    <App />
    </StorageProvider>
  </React.StrictMode>
  // <BrowserRouter>
  //   {/* The rest of your app goes here */}
  //   <Routes>
  //       <Route path="/" element={<App />} />
  //     </Routes>
  // </BrowserRouter>
);
