import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/new-loader.json';
import { LottieContainer } from '../Bootstrap';

const LottieLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <LottieContainer>
      <Lottie options={defaultOptions} height={100} width={100} />
    </LottieContainer>
  );
};

export default LottieLoader;
